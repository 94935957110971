import dotenv from 'dotenv';

dotenv.config();

const environment = process.env.ENVIRONMENT;

const envConfig = {
  BASE_URL: environment === 'development' ? process.env.REACT_APP_BASE_URL_LOCAL : process.env.REACT_APP_BASE_URL,
};

export default envConfig;
