import { useRef } from 'react';
import { Documents, GetDocuments } from '../../components/Dashboard/UpdateProfile/types';
import axiosInstance from '../axios';
import { localstorage } from '../localStorageServices';
import { AddressData, BankData, IActiveDocument, ProfileData } from '../../types';

export const getProfileData = async (): Promise<ProfileData> => {
  const res = await axiosInstance.get('userprofiles/myprofile');
  // alert('From here <<<<<>>>>>>.')
  // console.log(res, "<<<<<<>>>>>>>>>");

  const resData = await res.data;
  return resData.profile.general;
};
export const getBankData = async (): Promise<BankData> => {
  const res = await axiosInstance.get('userprofiles/myprofile');
  const resData = await res.data;
  const banks = resData.profile.bankDetail;
  const lastValue = banks[Object.keys(banks).pop()];
  return lastValue;
};
export const getDocumentData = async (): Promise<GetDocuments[]> => {
  const res = await axiosInstance.get('userprofiles/document');
  const resData = await res.data;
  // console.log("response", resData)

  // const temp = resData?.filter((docs) => docs?.documentTypeId === '5');
  // const last = temp[temp?.length - 1];
  return resData;
};

export const getPermAddressData = async (): Promise<AddressData> => {
  const res = await axiosInstance.get('userprofiles/myprofile');
  const resData = await res.data;
  const address = resData.profile.permanentAddress;
  const lastValue = address[Object.keys(address).pop()];
  return lastValue;
};

export const getTempAddressData = async (): Promise<AddressData> => {
  const res = await axiosInstance.get('userprofiles/myprofile');
  const resData = await res.data;
  const address = resData.profile.temporaryAddress;
  const lastValue = address[Object.keys(address).pop()];
  return lastValue;
};

export const getUserPermProvince = async (provinceId): Promise<AddressData> => {
  const res = await axiosInstance.get(`address/${provinceId}/provinces`);
  const resData = await res.data;
  return resData.name;
};

export const getUserPermDistrict = async (districtId): Promise<AddressData> => {
  const res = await axiosInstance.get(`address/districts/${districtId}`);
  const resData = await res.data;
  return resData.name;
};

export const getUserPermMunicipality = async (municipalityId: number): Promise<AddressData> => {
  const res = await axiosInstance.get(`address/municipalities/${municipalityId}`);
  const resData = await res.data;
  return resData.name;
};

export const getUserTempProvince = async (provinceId): Promise<AddressData> => {
  const res = await axiosInstance.get(`address/${provinceId}/provinces`);
  const resData = await res.data;
  return resData.name;
};

export const getUserTempDistrict = async (districtId): Promise<AddressData> => {
  const res = await axiosInstance.get(`address/districts/${districtId}`);
  const resData = await res.data;
  return resData.name;
};

export const getUserTempMunicipality = async (municipalityId): Promise<AddressData> => {
  const res = await axiosInstance.get(`address/municipalities/${municipalityId}`);
  const resData = await res.data;
  return resData.name;
};

export const getAllActiveDocuments = async (): Promise<IActiveDocument[]> => {
  const res = await axiosInstance.get('documenttypes/active');
  const resData = await res?.data;
  return resData[0];
};

export const patchProfileData = async (data, setChanged): Promise<any> => {
  await axiosInstance.patch(`/userprofiles/edit`, data);
  setChanged(true);
};

export const putDocumentData = async (
  mainId: number,
  data: Documents,
  setEditClicked: React.Dispatch<React.SetStateAction<boolean>>,
  setisReload: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<Documents> => {
  // console.log("requestData",data,)
  const res = await axiosInstance.put(`/userprofiles/${mainId}/document`, data);
  const resData = await res.data;
  // console.log("responseData",resData);
  // localstorage.setItem('editDocument', resData);
  setEditClicked(false);
  setisReload(true);

  // return resData.id;
  return resData;
};
export const getUserInfo = async () => {
  const res = await axiosInstance.get('users/me');
  const resData = await res.data;
  return resData.user;
};

export const getProjects = async () => {
  const res = await axiosInstance.get('/projects/all/list');
  const resData = res.data.data;
  return resData;
};
