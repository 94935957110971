import React from 'react';
import * as style from '../../styles/main.module.scss';
import AlertIcon from './AlertIcon';

interface Iprops {
  type?: string;
  name: string;
  className?: string;
  id?: string;
  placeholder?: string;
  value?: string | number | boolean | Date;
  setValue?: any;
  checked?: boolean | string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  register?;
  icon?: React.ReactElement;
  imageUrl?: string;
  label: string;
  errorMessage?: any;
  requied?: boolean;
  imageClassName?: any;
  editData?: boolean;
  showData?: any;
  imageWidth?: number;
  imageHeight?: number;
  issame?: boolean;
  issamevalue?: any;
  issamelable?: any;
  optionToMap: any[];
  defaultValues: string;
}

const CustomSelect: React.FC<Iprops> = (props, { ...rest }) => {
  const {
    errorMessage,
    requied,
    optionToMap,
    label,
    imageWidth,
    imageHeight,
    imageClassName,
    issamevalue,
    issamelable,
    icon,
    issame,
    imageUrl,
    register,
    type,
    name,
    className,
    id,
    placeholder,
    value,

    showData,
    checked,
    setValue,
    editData,
    defaultValues,
    onChange,
  } = props;

  const labelCalssName = [editData ? style.edit_label : style.inactive_label].join(' ');
  const labelFixName = value === '25%' ? style.ellipsis_label : style.default_label;

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: editData ? 'flex-start' : 'flex-start',
        // justifyContent: editData ? 'flex-start' : 'center',
        flexDirection: editData ? 'column' : 'row',
        gap: '10px',
      }}
    >
      {!editData && (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
            // left: '5px',
          }}
        >
          {icon ? (
            icon
          ) : (
            <div
              style={{
                height: '25px',
                // objectFit:'cover',
                // background:"red",
              }}
            >
              <img
                src={imageUrl}
                alt="user"
                style={{
                  objectFit: 'contain',
                  width: imageWidth ? imageWidth : 20,
                  height: imageHeight ? imageHeight : 16,
                }}
                // className={`${style.gender_icon}`}
              />
            </div>
          )}
        </div>
      )}

      <div
        style={{
          lineHeight: '24px',
          display: 'flex',
          marginTop: '3px',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <label
            style={{
              // margin: '0px',
              marginBottom: '8px',
              // marginTop: '5px',
            }}
            className={labelCalssName}
          >
            {label}
          </label>

          {requied && editData && (
            <span
              className="text-danger"
              style={{
                marginLeft: '3px',
                position: 'relative',
                top: '-5px',
              }}
            >
              *
            </span>
          )}
        </div>
        {editData ? (
          <div
            style={{
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <select
                className={className}
                style={{
                  paddingLeft: '35px',
                  margin: '0px',
                }}
                // defaultValue={setValue}
                id={name}
                name={name}
                // placeholder={placeholder}
                {...props}
                {...register(name)}
                onChange={onChange}
              >
                {issame ? (
                  <option selected={true} value={issamevalue} label={issamelable} />
                ) : (
                  <>
                    {/* {setValue && (
                      <option value={setValue} selected>
                        {showData}
                      </option>
                    // )} */}
                    <option value="" hidden={true} disabled={true}>
                      {placeholder}
                    </option>

                    {optionToMap?.map((item: any, index: any) => {
                      // console.log('set Value',setValue)
                      // console.log('set Value item id',item.id)
                      return (
                        <option
                          // selected={item.name?(defaultValues === item.name):(defaultValues === item.fullName)}
                          selected={defaultValues === item.id}
                          key={index}
                          value={item.id}
                        >
                          {item.name || item.fullName}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: '5px',
                }}
              >
                {icon ? (
                  icon
                ) : (
                  <div
                    style={{
                      height: '25px',
                      // objectFit:'cover',
                      // background:"red",
                    }}
                  >
                    <img
                      src={imageUrl}
                      alt="user"
                      className={imageClassName}
                      style={{
                        objectFit: 'contain',
                        width: imageWidth ? imageWidth : 20,
                        height: imageHeight ? imageHeight : 16,
                      }}
                      // className={`${style.gender_icon}`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p
            style={{
              fontSize: '14px',
              textTransform: 'capitalize',
            }}
          >
            {' '}
            {showData}{' '}
          </p>
        )}
      </div>

      {/* ERROR SHOWING */}

      <div
        style={{
          display: errorMessage ? 'block' : 'none',
        }}
        className="invalid-feedback text-left"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-exclamation-triangle"
            viewBox="0 0 16 16"
          >
            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
          </svg>
        </span>
        <span role="alert" style={{ zIndex: 10 }}>
          &nbsp;{errorMessage}
        </span>
      </div>
    </div>
  );
};

export default CustomSelect;
