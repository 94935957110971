import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { localstorage } from '../services/localStorageServices';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const accessToken = localstorage.getItem('accessToken');
  return (
    <Route
      {...rest}
      render={(props) => {
        if (accessToken) {
          return <Component />;
        } else {
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
