import * as yup from 'yup';

export const LoginFormSchema = yup.object().shape({
  email: yup.string().required('This field is required').email('Invalid username'),
  password: yup
    .string()
    .required('This field is required')
    .min(8, 'Invalid Password')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, 'Invalid Password'),
});

export const validationSchema = yup.object().shape(
  {
    firstName: yup.string().required(),
    lastName: yup.string().max(5).required(),
    min: yup.number().min(10),
    max: yup.number().max(20),
    minDate: yup.date().min('2021-08-01'),
    maxDate: yup.date().max('2021-08-01'),
    minLength: yup.string().min(2),
    minRequiredLength: yup.string().min(2).required(),
    selectNumber: yup.string().required(),
    pattern: yup.string().matches(/\d+/),
    radio: yup.string().required(),
    checkbox: yup.string().required(),
    exclusivelyRequiredOne: yup.string().when('exclusivelyRequiredTwo', {
      is: '',
      then: yup.string().required(),
      otherwise: yup.string().length(0),
    }),
    exclusivelyRequiredTwo: yup.string().when('exclusivelyRequiredOne', {
      is: '',
      then: yup.string().required(),
      otherwise: yup.string().length(0),
    }),
  },
  [['exclusivelyRequiredOne', 'exclusivelyRequiredTwo']],
);

// Form Validation Schema using Yup Library
export const signupValidationSchema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  middleName: yup.string(),
  lastName: yup.string().required('This field is required'),
  email: yup.string().email().required('This field is required'),
  employeeTypeId: yup.string().required('This field is required'),
  roleId: yup.string().required('This field is required'),
  departmentId: yup.string().required('This field is required'),
  designationId: yup.string().required('This field is required'),
  managerId: yup.string().required('This field is required'),
  shiftId: yup.string().required('This field is required'),
  citizenshipNumber: yup.string(),
  citNumber: yup.string(),
  ssf: yup.string(),
  contactNumber: yup.string().required('This field is required'),
  joinDate: yup.string().required('This field is required'),
});

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must have at least one Uppercase, one Lowercase, one Number and one Special Case Character',
    ),
  confirmPassword: yup
    .string()
    .required('Password is required')
    .oneOf([yup.ref('password'), null], 'Passwords do not match'),
});

export const forgetPasswordSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid Email Address'),
});

export const PasswordChangeSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required('This field is required')
    .min(8, 'Invalid Password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must have at least one Uppercase, one Lowercase, one Number and one Special Case Character',
    ),
  newPassword: yup
    .string()
    .required('This field is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must have at least one Uppercase, one Lowercase, one Number and one Special Case Character',
    ),
  confirmPassword: yup
    .string()
    .required('This field is required')
    .oneOf([yup.ref('newPassword'), null], 'Passwords do not match'),
});
