@import 'color.scss';
@import 'image.scss';
@import 'card.scss';
@import 'container.scss';
@import 'input.scss';
@import 'icon.scss';
@import 'button.scss';
@import 'modal.scss';

body {
  font-family: 'Roboto', sans-serif;
}
