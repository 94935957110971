import React from 'react';
import axiosInstance from './axios';
import { toast } from 'react-hot-toast';
import { history } from '../pages';
import { UserMail } from '../components/ForgetPassword/ForgetPasswordForm';
import { localstorage } from './localStorageServices';

export const login = async (data: UserLoginForm): Promise<void> => {
  const res = await axiosInstance.post('/login', data);
  const resData = await res.data;
  localstorage.setItem('accessToken', resData.access_token);
  history.push('/dashboard');
};
export const getDepartment = async (): Promise<Department[]> => {
  const res = await axiosInstance.get('/departments');
  const resData = await res.data;
  return resData.rows;
};
export const getProject = async () => {
  const res = await axiosInstance.get('/projects/all/list');
  const resData = await res.data;
  return resData.data;
};

export const assign = async (userid: number, data: any) => {
  const res = await axiosInstance.post('/projects/userprojects/assign/' + userid, data);
  const resData = await res.data;
  return resData;
};
export const getUserProject = async (id: number) => {
  const res = await axiosInstance.get('/projects/userproject/' + id);
  const resData = await res.data;
  return resData;
};
export const getDesignation = async (): Promise<Designation[]> => {
  const res = await axiosInstance.get('/designations');
  const resData = await res.data;
  return resData.rows;
};
export const getRole = async (): Promise<Role[]> => {
  const res = await axiosInstance.get('/roles');
  const resData = await res.data;
  return resData.rows;
};
export const getManager = async (): Promise<Manager[]> => {
  const res = await axiosInstance.get('/users/allmanagers');
  const resData = await res.data;
  return resData[0];
};

export const getEmployeeType = async (): Promise<EmployeeType[]> => {
  const res = await axiosInstance.get('/employeetypes');
  const resData = await res.data;
  return resData.rows;
};

export const getShift = async (): Promise<ShiftType[]> => {
  const res = await axiosInstance.get('/shifts');
  const resData = await res.data;
  return resData.rows;
};

export const userInformation = async (userStore) => {
  const res = await axiosInstance.get('users/me/');
  const resData = await res.data;
  userStore.addUser(resData.user);
};

export const changePassword = async (
  data: IPasswordChange,
  setIsPasswordSet: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> => {
  const res = await axiosInstance.put('/users/me/change-password', data);
  const resData = await res.data;
  setIsPasswordSet(false);
};

export const signup = async (data): Promise<void> => {
  const res = await axiosInstance.post('/register', data);
  const resData = await res.data;
  history.push('/login');
};

export const getAllUser = async (): Promise<IAllUser[]> => {
  const res = await axiosInstance.get('/users');
  const resData = await res.data.rows;
  return resData;
};

export const getMe = async (): Promise<IUserStore> => {
  const res = await axiosInstance.get('users/me');
  const resData = await res.data;
  return resData.user;
};

export const userUnderManager = async (): Promise<IAllUser[]> => {
  const res = await axiosInstance.get('/users/userundermangaer');
  const resData = await res.data[0];
  return resData;
};

export const forgetPassword = async (
  data: UserMail,
  sentLinkSent: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> => {
  const res = await axiosInstance.post('/forgot-password', data);
  sentLinkSent(true);
};

export const resetPassword = async (
  data,
  setPasswordChanged: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<void> => {
  const res = await axiosInstance.post('/reset-password', data);
  setPasswordChanged(true); // trigger successful message after successful password change
};

export const logout = (removeUser: () => void) => {
  removeUser();
  history.push('/login');
};

export const getNotifications = async (): Promise<Notifications[]> => {
  const res = await axiosInstance.get('/notifications/mynotifications');
  const resData = await res.data;
  return resData;
};

export const markReadNotifications = async (setMarkedRead: React.Dispatch<React.SetStateAction<boolean>>): Promise<void> => {
  const res = await axiosInstance.put('/notifications');
  const resData = await res.data;
  setMarkedRead((prevState) => !prevState);
  return resData;
};
