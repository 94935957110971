import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Route } from 'react-router-dom';
import Dashboard from '../../../pages/DashBoardPage/Dashboard';
import { localstorage } from '../../../services/localStorageServices';
import {
  createProfile,
  uploadDocument,
  createDocument,
  getAllDocuments,
  uploadPicture,
  updateProfile,
  getUserDocuments,
} from '../../../services/ProfileServices/updateProfileService';
import * as style from '../../../styles/main.module.scss';
import { Documents, Profile, DocumentTypes } from './types';
import UploadDocumentType from './UploadDocumentType';
import { useHistory } from 'react-router-dom';
import { profile } from 'console';
import { IUserStore } from '../../../types';

interface IProps {
  nextPage: () => void;
  setDocumentInfo: React.Dispatch<React.SetStateAction<any>>;
  prevPage: () => void;
  userId: number;
}

const UploadDocument: React.FC<IProps> = ({ prevPage, setDocumentInfo, nextPage, userId }) => {
  const user: IUserStore = JSON.parse(localStorage.getItem('user'));

  const [apiDocs, setApiDocs] = useState<DocumentTypes[]>();
  const [userDocs, setUserDocs] = useState<Documents[]>();
  const { register, handleSubmit, watch, reset, setValue } = useForm();
  let i = 0;
  const history = useHistory();

  useEffect(() => {
    getAllDocuments()
      .then((res) => {
        setApiDocs(res);
      })
      .catch((err) => toast.error(err.message));
    if (userId) {
      getUserDocuments(userId)
        .then((res) => {
          setUserDocs(res);
        })
        .catch((err) => toast.error(err.message));
    }
  }, []);

  const filterdocs = (name: string) => {
    const docs = apiDocs.filter((data) => data.name === name);

    return docs[0].id;
  };

  const uploadDocs = (array: string[]) => {
    array.map(async (name) => {
      const document = localstorage.getItem(`${name}document`);
      const docName = localstorage.getItem(`${name}name`);

      const docId = filterdocs(name);

      const docUrl = await uploadDocument(document, docName);

      localStorage.removeItem(`${name}document`);
      localStorage.removeItem(`${name}name`);

      let userIdToUpdate;
      if (userId) {
        userIdToUpdate = userId;
      } else {
        userIdToUpdate = i;
      }
      const Document: Documents = {
        // issuedById: +user?.id,
        issuedById: +userIdToUpdate,
        uploadedUrl: docUrl,
        documentTypeId: +docId,
        documentName: docName,
      };

      createDocument(Document);
    });
  };
  const onSubmit = async (data) => {
    const profileInformation = JSON.parse(localstorage.getItem('updatePersonalInfo'));
    const officialInformation = JSON.parse(localstorage.getItem('officialinformation'));
    const addressInformation = JSON.parse(localstorage.getItem('addressInformation'));
    const bankInformation = JSON.parse(localstorage.getItem('updateBankInfo'));
    // const imageUrl = localstorage.getItem('imageUrl');

    const submittedProfile: Profile = {
      ...profileInformation,
      ...officialInformation,
      ...addressInformation,
      ...bankInformation,
      // maritalStatus: profileInformation?.maritalStatus === 'Unmarried' ? 0 : 1,
      permDistrictId: parseInt(addressInformation?.permDistrictId),
      permMunicipalityVdcId: parseInt(addressInformation?.permMunicipalityVdcId),
      permProvinceId: parseInt(addressInformation?.permProvinceId),
      permStreet: addressInformation?.permStreet,
      permWard: parseInt(addressInformation?.permWard),
      tempDistrictId: parseInt(addressInformation?.tempDistrictId),
      tempMunicipalityVdcId: parseInt(addressInformation?.tempMunicipalityVdcId),
      tempProvinceId: parseInt(addressInformation?.tempProvinceId),
      tempStreet: addressInformation?.tempStreet,
      tempWard: parseInt(addressInformation?.tempWard),
      isUpdated: true,
    };
    if (!userId) {
      await createProfile(submittedProfile).then((res) => {
        const { id } = res.user.id;
        i = id;
        toast.success(`successfully created`);
      });
    } else {
      updateProfile(userId, submittedProfile)
        .then((res) => {
          toast.success(`successfully edited`);
        })
        .catch((err) => toast.error(err.message));
    }
    uploadDocs(apiDocs?.map((docs) => docs.name));

    localstorage.setItem('updateDocument', JSON.stringify(data));
    history.push('/dashboard');
    localStorage.removeItem('updateDocument');
    localStorage.removeItem('updatePersonalInfo');

    // setTimeout(toast.success('profile updated suceessfully'));
  };

  return (
    <div className={`${style.profile_body} ${style.upload_docs}`}>
      <div className={`${style.personalInfo_section}`}>
        <div className={`${style.form_section}`}>
          <h5 className={`${style.form_section_title}`}>Documents Upload</h5>
          {/* <div className={`${style.form_bg_doc}`}> */}
          <div>
            <form method="POST" onSubmit={handleSubmit(onSubmit)}>
              <div className={`${style.form_bg_container}`}>
                <div className={`row ${style.form_gutter}`}>
                  {/* first row  */}
                  {apiDocs?.map((docs) => {
                    const matchingObject = userDocs?.find((userDoc) => Number(userDoc.documentTypeId) === Number(docs.id));
                    const userFilename = matchingObject
                      ? matchingObject.documentName
                        ? matchingObject.documentName
                        : ''
                      : '';
                    const userFileId = matchingObject ? (matchingObject.id ? matchingObject.id : '') : '';
                    return (
                      <UploadDocumentType
                        name={docs.name}
                        key={docs.name}
                        userFile_name={userFilename}
                        userFileId={userFileId}
                      />
                    );
                  })}
                </div>
              </div>

              <div className={`${style.group_btn_offical}`}>
                <button className={`${style.prev_btn} btn btn`} onClick={() => prevPage()}>
                  Back
                </button>
                <input className={`${style.submit_btn_final} btn btn`} type="submit" value="Submit" />
              </div>
            </form>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default UploadDocument;
