import { IFiscalYearGetAllList, IFiscalYearGetOneList } from '../../components/Admin/AdminFiscalYear/fiscalYearTypes';
import axiosInstance from './../axios';
import { UserDetails } from '../../types';

export const read = async (url: string): Promise<Designation[] | Department[] | Role[] | ILeaveType[] | Document[]> => {
  const res = await axiosInstance.get(`/${url}`);
  const resData = await res.data;
  return resData.rows;
};

export const readUserList = async (title: string, id: string, pageno: number): Promise<Pagination> => {
  const res = await axiosInstance.get(`users/userswith${title}/${id}/${pageno}`);
  const resData = await res.data;

  return resData;
};

export const readAllUsers = async (): Promise<ISignUpForm[]> => {
  const res = await axiosInstance.get('/users');
  const resData = await res.data;
  return resData.rows;
};
export const readAllUsersPaginated = async (page: number, key: string): Promise<Pagination> => {
  const res = await axiosInstance.get(`/users/search/${page}?key=${key}`);

  const resData = await res.data;
  return resData;
};

export const readUserById = async (id: number): Promise<UserDetails> => {
  const res = await axiosInstance.get(`/users/${id}`);
  const resData = await res.data;
  return resData.user;
};

export const readDetailsById = async (id: number): Promise<any> => {
  const res = await axiosInstance.get(`/users/details/${id} `);
  return await res.data;
};
// export const readUserById = async (id: number): Promise<UserDetails> => {
//   const res = await axiosInstance.get(`/UserProfile/getEmpData/${id}`);
//   const resData = await res.data;
//   return resData.user;
// };

export const readSubOrdinatesAdmin = async (id: number): Promise<SubOrdinates[]> => {
  const res = await axiosInstance.get(`/users/${id}/subordinates`);
  const resData = await res.data;
  return resData[0];
};

export const getAllFiscalYear = async (): Promise<IFiscalYearGetAllList[]> => {
  const res = await axiosInstance.get(`/calandaryears`);

  const resData = await res.data.rows;
  return resData;
};
export const getOneFiscalYear = async (id: number): Promise<IFiscalYearGetOneList> => {
  const res = await axiosInstance.get(`/calandaryears/${id}`);
  const resData = await res.data;
  return resData;
};

export const readAllProject = async (): Promise<any[]> => {
  const res = await axiosInstance.get('/projects/all/list');
  const resData = await res.data;
  return resData.data;
};

export const readAllProjectReport = async (): Promise<any[]> => {
  const res = await axiosInstance.get('/projects/userproject/report/view');
  const resData = await res.data;
  return resData;
};

export const readAllProjectType = async (): Promise<any[]> => {
  const res = await axiosInstance.get('/projects/all/projecttype');
  const resData = await res.data;
  return resData.data;
};
