import React from 'react';
import * as style from '../../styles/main.module.scss';
import AlertIcon from './AlertIcon';
import { bottom } from '@popperjs/core';

interface Iprops {
  type: string;
  name?: string;
  className?: string;
  id?: string;
  placeholder?: string;
  value?: string | number | boolean | Date;
  checked?: boolean | string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  register?;
  icon?: React.ReactElement;
  imageUrl?: string;
  label: string;
  imageWidth?: number;
  imageHeight?: number;
  errorMessage?: any;
  requied?: boolean;
  // maxLength?: any;
  editData?: boolean;
  showData?: any;
}

const InputWithIcon: React.FC<Iprops> = (props, { ...rest }) => {
  const {
    errorMessage,
    requied,
    label,
    // maxLength,
    icon,
    imageUrl,
    register,
    type,
    name,
    imageHeight,
    imageWidth,
    className,
    id,
    placeholder,
    value,
    checked,
    onChange,
    editData,
    showData,
  } = props;

  // console.log('props with icon input ', props);
  const labelCalssName = [editData ? style.edit_label : style.inactive_label].join(' ');

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: editData ? 'column' : 'row',
        justifyContent: editData ? 'flex-start' : 'flex-start',
        gap: '10px',
      }}
    >
      {!editData && (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',

            // top: '0%',
            // transform: 'translateY(-30%)',
            // left: '5px',
          }}
        >
          {icon ? (
            icon
          ) : (
            <div
              style={{
                height: '25px',
                // objectFit:'cover',
                // background:"red",
              }}
            >
              <img
                src={imageUrl}
                alt="user"
                style={{
                  objectFit: 'contain',
                  width: imageWidth ? imageWidth : 20,
                  height: imageHeight ? imageHeight : 16,
                }}
                // className={`${style.gender_icon}`}
              />
            </div>
          )}
        </div>
      )}
      <div
        style={{
          lineHeight: '24px',
          display: 'flex',
          marginTop: '3px',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <label
            style={{
              // margin: '0px',
              marginBottom: '7px',
              // marginTop: '2px',
            }}
            className={labelCalssName}
          >
            {label}
          </label>
          {requied && editData && (
            <span
              style={{
                marginLeft: '3px',
                color: '#dc3545',

                position: 'relative',
                top: '-5px',
              }}
              // className="text-danger"
            >
              *
            </span>
          )}
        </div>
        {editData ? (
          <div
            style={{
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {register ? (
                <input
                  type={type}
                  // maxLength={10}
                  {...props}
                  // {...register(name, name === 'contactNumber' && { shouldUnregister: false })}
                  {...register(name)}
                  className={className}
                  id={id}
                  checked={checked}
                  defaultValue={showData}
                  onChange={onChange}
                  placeholder={placeholder}
                  value={value}
                  style={{
                    paddingLeft: 32,
                    paddingTop: 11,
                  }}
                />
              ) : (
                <input
                  type={type}
                  {...props}
                  className={className}
                  id={id}
                  name={name}
                  // checked={checked}
                  // defaultValue={value}
                  onChange={onChange}
                  placeholder={placeholder}
                  // value={value}
                  style={{
                    paddingLeft: 32,
                    paddingTop: 11,
                  }}
                />
              )}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  left: '5px',
                }}
              >
                {icon ? (
                  icon
                ) : (
                  <div
                    style={
                      {
                        // objectFit:'cover',
                        // background:"red",
                      }
                    }
                  >
                    <img
                      src={imageUrl}
                      alt="user"
                      style={{
                        objectFit: 'contain',
                        width: imageWidth ? imageWidth : 20,
                        height: imageHeight ? imageHeight : 16,
                      }}
                      // className={`${style.gender_icon}`}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p
            style={{
              fontSize: '14px',
            }}
            className={style.showData}
          >
            {showData}
          </p>
        )}
      </div>

      <div
        style={{
          display: errorMessage && editData ? 'block' : 'none',
        }}
        className="invalid-feedback text-left"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-exclamation-triangle"
            viewBox="0 0 16 16"
          >
            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
          </svg>
        </span>
        <span role="alert" style={{ zIndex: 10 }}>
          &nbsp;{errorMessage}
        </span>
      </div>
    </div>
  );
};

export default InputWithIcon;
