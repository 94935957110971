import * as yup from 'yup';

// export function checkIfFilesAreTooBig(files?: [File]): boolean {
//   let valid = true;
//   if (files) {
//     files.map((file) => {
//       const size = file.size / 1024 / 1024;
//       if (size > 10) {
//         valid = false;
//       }
//     });
//   }
//   return valid;
// }

// export function checkIfFilesAreCorrectType(files?: [File]): boolean {
//   let valid = true;
//   if (files) {
//     files.map((file) => {
//       if (!['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
//         valid = false;
//       }
//     });
//   }
//   return valid;
// }

export const UpdatePersonalInfo = yup.object().shape({
  fullName: yup
    .string()
    .matches(/^[a-zA-Z]+([ ][a-zA-Z]+)?([ ][a-zA-Z]+)?$/, 'Enter a valid name')
    .required('This is required'),
  email: yup.string().required('This field is required').email('Invalid email address'),
  gender: yup.string().required('This field is required'),
  dateOfBirth: yup
    .string()
    .required('This field is required')
    .test('is-age-10-or-older', 'Please enter a valid date', (value) => {
      // Check if the provided date of birth corresponds to someone who is 10 or older
      const birthDate = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();

      // Adjust for leap years
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
      ) {
        return age - 1 >= 10;
      }

      return age >= 10;
    }),
  contactNumber: yup
    .string()
    .required('This field is required')
    .matches(/^[0-9]+$/i, ' Must be exactly 10 digits')
    .test('len', 'Must be exactly 10 digits', (val) => val && val.length === 10),

  // emergencyContactNumber: yup
  //   .string()
  //   .required('This field is required')
  //   .matches(/^[0-9]+$/i, 'Must be only digits')
  //   .test('len', 'Must be exactly 10 digits', (val) => val && val.length === 10),

  fatherName: yup
    .string()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .matches(/^[a-zA-Z]+([ ][a-zA-Z]+)?([ ][a-zA-Z]+)?$/i, 'Enter a valid name'),

  motherName: yup
    .string()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .matches(/^[a-zA-Z]+([ ][a-zA-Z]+)?([ ][a-zA-Z]+)?$/i, 'Enter a valid name'),

  // panNumber: yup
  //   .string()
  //   .required('This field is required')
  //   .matches(/^[0-9]+$/, 'Must only be digits')
  //   .test('len', 'Must be exactly 9 digits', (val) => val.length === 9),

  // maritalStatus: yup.string().required('This field is required'),

  // spouseName: yup.string().when('maritalStatus', {
  //   is: '1',

  //   then: yup
  //     .string()
  //     .required('This field is required')
  //     .matches(/^[a-zA-Z]+([ ][a-zA-Z]+)?([ ][a-zA-Z]+)?$/i, 'Enter a valid name'),
  //   otherwise: yup.string().notRequired(),
  // }),

  // spouseContactNumber: yup.string().when('maritalStatus', {
  //   is: '1',
  //   then: yup.string().matches(/^[-a-z0-9_ ()]+$|^$/i, 'Enter a Valid Number'),
  //   // .required('This field is required')
  //   // .matches(/^[0-9]+$/, ' Must be exactly 10 digits'),

  //   otherwise: yup.string().notRequired(),
  // }),

  // spouseContactNumber: yup
  //   .string()
  //   .required('This field is required')
  //   .matches(/^[0-9]+$/i, 'Must be only digits')
  //   .test('len', 'Must be exactly 10 digits', (val) => val && val.length === 10),
  // otherwise: yup.string().notRequired(),

  // image: yup
  //   .mixed()
  //   .test('required', 'You need to provide a image file', (value) => {
  //     return value && value.length;
  //   })
  //   .test('fileSize', 'The file is too large', (value, context) => {
  //     return value && value[0] && value[0].size <= 200000;
  //   }),
});

export const EditProfileDetails = yup.object().shape({
  contactNumber: yup
    .string()
    .required('This field is required')
    .matches(/^[0-9]{10}$/, ' Must be exactly 10 digits')
    .test('len', 'Must be exactly 10 digits', (val) => val && val.length === 10),

  emergencyContactNumber: yup
    .string()
    .required('This field is required')
    .matches(/^[0-9]+$/i, 'Must be only digits')
    .test('len', 'Must be exactly 10 digits', (val) => val && val.length === 10),
  emergencyContactRelation: yup
    .string()
    .matches(/^[a-zA-Z]+([ ][a-zA-Z]+)?([ ][a-zA-Z]+)?$/, 'Enter a valid name')
    .required('This field is required'),

  fatherName: yup
    .string()
    .required('This field is required')
    .matches(/^[a-zA-Z]+([ ][a-zA-Z]+)?([ ][a-zA-Z]+)?$/i, 'Enter a valid name'),

  motherName: yup
    .string()
    .required('This field is required')
    .matches(/^[a-zA-Z]+([ ][a-zA-Z]+)?([ ][a-zA-Z]+)?$/i, 'Enter a valid name'),

  // maritalStatus: yup.string().required('This field is required'),

  // spouseName: yup.string().when('maritalStatus', {
  //   is: '1',

  //   then: yup
  //     .string()
  //     .required('This field is required')
  //     .matches(/^[a-zA-Z]+([ ][a-zA-Z]+)?([ ][a-zA-Z]+)?$/i, 'Enter a valid name'),
  //   otherwise: yup.string().notRequired(),
  // }),

  // spouseContactNumber: yup.string().when('maritalStatus', {
  //   is: '1',
  //   then: yup.string().matches(/^[-a-z0-9_ ()]+$|^$/i, 'Enter a Valid Number'),

  //   otherwise: yup.string().notRequired(),
  // }),
});
