#button {
  background-color: $logo-blue;
  border-radius: 15px;
}

#button:hover {
  background-color: $logo-blue-dark;
}
#getstartedbtn {
  width: 200px;
  height: 50px;
  background: #eb6a25;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  border: 1px solid transparent;
}
