#containerCard {
  position: relative;
  background-color: $white;
  min-height: 45vh;
  width: 24%;
  margin-top: -25vh;
  border-radius: 5px;
}
//getstarted
#getstartedContainer {
  position: relative;
  background-color: $white;
  min-height: 45vh;
  width: 60%;
  margin-top: -25vh;
  border-radius: 5px;
}
@media screen and (min-width: 1024px) and (max-width: 1030px) {
  #containerCard {
    margin-top: -15vh;
    width: 39%;
    padding-top: 20px;
    min-height: 27vh;
  }
}

@media screen and (min-width: 760px) and (max-width: 830px) {
  #containerCard {
    margin-top: -15vh;
    width: 50%;
    min-height: 32vh;
  }
}

@media screen and (min-width: 421px) and (max-width: 740px) {
  #containerCard {
    margin-top: -23vh;
    width: 65%;
    min-height: 48vh;
  }
}

@media screen and (max-width: 420px) {
  #containerCard {
    margin-top: -23vh;
    min-height: 40vh;
    width: 90%;
  }
}
