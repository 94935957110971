import {
  Provinces,
  District,
  Municipality,
  Profile,
  Documents,
  DocumentTypes,
} from '../../components/Dashboard/UpdateProfile/types';
import axiosInstance from '../axios';
import { localstorage } from '../../services/localStorageServices';

export const getAllProvinces = async (): Promise<Provinces[]> => {
  const res = await axiosInstance.get('/address/provinces');
  const resData = await res.data;
  return resData.rows;
};
export const getAllDistricts = async (id: number): Promise<District[]> => {
  const res = await axiosInstance.get(`/address/${id}/districts`);
  const resData = await res.data;
  return resData;
};
export const getAllMunicipalities = async (id: number): Promise<Municipality[]> => {
  const res = await axiosInstance.get(`/address/${id}/municipalities`);

  const resData = await res.data;
  return resData;
};
function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string

  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI?.split(',')[0]?.split(':')[1]?.split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const uploadPicture = async (rawImage: string, imageName: string) => {
  const blob = dataURItoBlob(rawImage);
  const formData = new FormData();
  formData.set('file', blob, imageName);
  const res = await axiosInstance({
    method: 'post',
    url: '/userprofiles/upload/image',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  const resData = res.data;
  // localstorage.setItem('imageUrl', resData);
  return resData;
};

export const createProfile = async (data: any) => {
  const res = await axiosInstance.post('/userprofiles/create', data);
  const resData = await res.data;
  return resData;
};

export const updateProfile = async (editId: number, data: any): Promise<any> => {
  const res = await axiosInstance.patch(`userprofiles/update/${editId}`, data);
  // toast.success(`${reqdata.firstName} ${reqdata.middleName} ${reqdata.lastName} successfully edited`);
  const resData = await res.data;
  return resData;
};

export const uploadDocument = async (rawFile: string, fileName: string) => {
  const blob = dataURItoBlob(rawFile);
  const formData = new FormData();
  formData.set('file', blob, fileName);

  const res = await axiosInstance({
    method: 'post',
    url: 'userprofiles/upload/document',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  const resData = res.data;
  // console.log(resData);
  localstorage.setItem('updateDocument', resData);
  return resData;
};

export const createDocument = async (datas: Documents) => {
  const res = await axiosInstance.post('/userprofiles/document', datas);
  const resData = res.data;
  return resData;
};
export const getAllDocuments = async (): Promise<DocumentTypes[]> => {
  const res = await axiosInstance.get('/documenttypes/active');
  const resData = await res.data;

  return resData[0];
};

export const getUserDocuments = async (id: number): Promise<Documents[]> => {
  const res = await axiosInstance.get(`/userprofiles/userDocument/${id} `);
  const resData = res.data;
  return resData;
};

export const delDoc = async (id: number) => {
  const res = await axiosInstance.delete(`/userprofiles/document/${id} `);
  const resData = res;
  return resData;
};
