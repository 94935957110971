import React, { FormEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as style from '../../style/main.module.scss';
import { PasswordChangeSchema } from '../../schemas/AuthSchema';
import AlertIcon from '../common/AlertIcon';
import InputFields from '../common/InputField';
import { changePassword } from '../../services/AuthService';

interface Props {
  setIsPasswordSet: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordChangeForm: React.FC<Props> = ({ setIsPasswordSet }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IPasswordChange>({
    resolver: yupResolver(PasswordChangeSchema),
  });

  const HandleShowPassword: React.MouseEventHandler<HTMLLIElement> = (): void => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data: IPasswordChange, e: FormEvent): void => {
    e.preventDefault();
    const newUserInfo = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    changePassword(newUserInfo, setIsPasswordSet);
    reset();
  };

  return (
    <>
      <div className="card-title pt-4">
        <h2 className="text-center">Change Password</h2>
      </div>
      <div className="card-body pt-0">
        <form method="POST" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group pt-3">
            <InputFields
              type={showPassword ? 'text' : 'password'}
              name="oldPassword"
              className={`form-control p-4 ${errors.oldPassword ? 'is-invalid' : ''} ${style.input}`}
              placeholder="Old Password"
              register={register}
            />
            <i
              style={{
                color: errors.oldPassword ? '#d92550' : !showPassword ? 'rgba(0,0,0,0.4)' : '#4492cf',
              }}
              className={showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}
              id={style.icon}
              onClick={HandleShowPassword}
              data-testid="password-toogleIcon"
            />
            <AlertIcon errorMessage={errors.oldPassword?.message} />
          </div>
          <div className="form-group pt-3">
            <InputFields
              type={showPassword ? 'text' : 'password'}
              name="newPassword"
              className={`form-control p-4 ${errors.newPassword ? 'is-invalid' : ''} ${style.input}`}
              placeholder="New Password"
              register={register}
            />
            <i
              style={{
                color: errors.newPassword ? '#d92550' : !showPassword ? 'rgba(0,0,0,0.4)' : '#4492cf',
              }}
              className={showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}
              id={style.icon}
              onClick={HandleShowPassword}
              data-testid="password-toogleIcon"
            />
            <AlertIcon errorMessage={errors.newPassword?.message} />
          </div>

          <div className="form-group pt-3">
            <InputFields
              type={showPassword ? 'text' : 'password'}
              name="confirmPassword"
              className={`form-control p-4 ${errors.confirmPassword ? 'is-invalid' : ''} ${style.input}`}
              placeholder="Confirm Password"
              register={register}
            />
            <i
              style={{
                color: errors.confirmPassword ? '#d92550' : !showPassword ? 'rgba(0,0,0,0.4)' : '#4492cf',
              }}
              className={showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}
              id={style.icon}
              onClick={HandleShowPassword}
              data-testid="password-toogleIcon"
            />
            <AlertIcon errorMessage={errors.confirmPassword?.message} />
          </div>

          <button id={style.button} type="submit" className="btn btn-primary btn-block btn-lg mt-3 mb-3">
            Continue
          </button>
        </form>
      </div>
    </>
  );
};

export default PasswordChangeForm;
