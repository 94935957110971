import loadingGif from '../../images/loading.gif';

const Loader = () => {
  return (
    <div className="container" style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <img className="loader" src={loadingGif} alt="loading.." style={{ width: 150 }} />
    </div>
  );
};

export default Loader;
