import React from 'react';
import '../../styles/main.module.scss';

interface Iprops {
  type: string;
  name: string;
  className?: string;
  id?: string;
  placeholder?: string;
  value?: string | number | boolean | Date;
  checked?: boolean | string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  register?;
}

const InputField: React.FC<Iprops> = (props, { ...rest }) => {
  const { register, type, name, className, id, placeholder, value, checked, onChange } = props;

  return (
    <input
      type={type}
      {...rest}
      {...register(name)}
      className={className}
      id={id}
      checked={checked}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default InputField;
