import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ComponentPage } from './Component';
import ProtectedRoute from '../utils/ProtectedRoute';
import '../styles/main.module.scss';
import CustomizedToaster from '../components/common/customToast';
import UploadDocument from '../components/Dashboard/UpdateProfile/UploadDocument';
import { createContext, useContext } from 'react';
import { RootStore } from '../stores/RootStore';
import { observer } from 'mobx-react';
import React from 'react';
import Loader from '../components/common/Loader';
import { uploadDocument } from '../services/ProfileServices/updateProfileService';
export const history = createBrowserHistory({ basename: '/' });
import 'html2canvas';

const LoginPage = React.lazy(() => import('./LoginPage'));
const Dashboard = React.lazy(() => import('./DashBoardPage/Dashboard'));
const SignUp = React.lazy(() => import('./SignUp'));
const ForgetPasswordPage = React.lazy(() => import('./ForgetPasswordPage/ForgetPasswordPage'));
const ChangePassword = React.lazy(() => import('./ChangePasswordPage/ChangePassword'));
const ResetPasswordPage = React.lazy(() => import('./ResetPasswordPage'));
const PasswordChangePage = React.lazy(() => import('./PasswordChangePage/PasswordChangePage'));
const UpdateProfilePage = React.lazy(() => import('./UpdateProfilePage/UpdateProfilePage'));
const GetStarted = React.lazy(() => import('./GetStarted'));
const FormSubmitted = React.lazy(() => import('./FormSubmitted'));

export function PageFrame() {
  //   const [permissionRes, setPermissionRes] = useState<IPermission[]>([])

  //   useEffect(()=>{

  //     getPermission().then((res=>{
  //       setPermissionRes(res);
  //     })).catch((err)=>{

  //       toast.error(err.message);
  //     })
  //   },[])
  //  if(permissionRes.length==0){
  //    return null
  //  }
  //   return(
  //     <AdminCreateRoleForm  permissionRes={permissionRes}/>
  //   )
  return (
    <div>
      <CustomizedToaster />
      <Router history={history}>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route exact={true} path="/" component={LoginPage} />
            <Route exact={true} path="/component">
              <ComponentPage />
            </Route>
            <Route exact={true} path="/login" component={LoginPage} />
            <Route exact={true} path="/signup" component={SignUp} />

            <Route exact={true} path="/forget-password" component={ForgetPasswordPage} />
            <Route exact={true} path="/reset-password" component={ChangePassword} />
            <ProtectedRoute exact={true} path="/change-password" component={PasswordChangePage} />
            <ProtectedRoute exact={true} path="/expiry-notify" component={ResetPasswordPage} />
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/getstarted" component={GetStarted} />
            <ProtectedRoute path="/formsubmit" component={FormSubmitted} />
            <ProtectedRoute path="/update-profile/:id?" component={UpdateProfilePage} />
            <Route path="/update-docs" component={UploadDocument} />

            <Redirect to="/login" />
          </Switch>
        </React.Suspense>
      </Router>
    </div>
  );
}
