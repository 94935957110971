import * as yup from 'yup';

export const updateofficialinfo = yup.object().shape({
  roleId: yup.string().required('This field is required'),
  joinDate: yup.string().required('This field is required'),
  departmentId: yup.string().required('This field is required'),
  designationId: yup.string().required('This field is required'),
  shiftId: yup.string().required('This field is required'),
  managerId: yup.string().required('This field is required'),
  employeeTypeId: yup.string().required('This field is required'),
  // citizenshipNumber: yup
  //   .string()
  //   .required('This field is required')
  //   .matches(/^[0-9]+$/, 'Ward Number should be numeric '),
  citizenshipNumber: yup.string().nullable(),
  citNumber: yup.string().nullable(),
  // citNumber: yup.string().nullable(),
  ssf: yup.string().nullable(),
});

export const officialinformation = yup.object().shape({
  citNumber: yup.string().nullable(),
  ssf: yup.string().nullable(),
});

export const documentValidate = yup.object().shape({
  file: yup
    .mixed()
    .test('fileType', 'Only PDF, JPG, JPEG files are allowed', (value) => {
      if (!value) return true; // If no file is selected, validation passes
      return (
        ['application/pdf', 'image/jpg', 'image/jpeg'].includes(value.type) && !value.name.toLowerCase().endsWith('.jfif') // Exclude jfif files
      );
    })
    .test('fileSize', 'File size must be less than 3 MB', (value) => {
      if (!value) return true; // If no file is selected, validation passes
      return value.size <= 3 * 1024 * 1024; // 5 MB in bytes
    }),
});
