import React, { useEffect, useState } from 'react';
import { localstorage } from '../../../services/localStorageServices';
import * as style from '../../../styles/main.module.scss';
import { delDoc, uploadDocument } from '../../../services/ProfileServices/updateProfileService';
import toast from 'react-hot-toast';
import DeleteModal from '../../Admin/common/DeleteModal';
import { documentValidate } from '../../../schemas/UpdateOfficialInfo';

const UploadDocumentType = ({ name, userFile_name, userFileId }) => {
  const [document, setDocument] = useState('');
  const [documentName, setDocumentName] = useState('');
  // const [showDelete, setShowDelete] = useState<boolean>(false);
  // const [delId, setDelId] = useState<undefined | number>(undefined);
  // const [changed, setChanged] = useState(false);
  // const [delName, setDelName] = useState<string>('');

  // const handleShowDelete = (): void => {
  //   setShowDelete(true);
  // };
  // const handleCloseDelete = (): void => {
  //   setShowDelete(false);
  // };

  // const handleDeleteDocument = (id: number): void => {
  //   handleShowDelete();
  //   const deleteData = userDocs?.filter((doc) => doc.id === id);
  //   const deleteObject = deleteData[0];
  //   setDelId(id);
  //   setDelName(documentName);
  //   delDoc(id)
  // };
  // const [showDelete, setShowDelete] = useState<boolean>(false);
  // const [delId, setDelId] = useState<undefined | number>(undefined);
  // const [changed, setChanged] = useState(false);
  // const [delName, setDelName] = useState<string>('');

  // const handleShowDelete = (): void => {
  //   setShowDelete(true);
  // };
  // const handleCloseDelete = (): void => {
  //   setShowDelete(false);
  // };

  // const handleDeleteDocument = (id: number): void => {
  //   handleShowDelete();
  //   const deleteData = userDocs?.filter((doc) => doc.id === id);
  //   const deleteObject = deleteData[0];
  //   setDelId(id);
  //   setDelName(documentName);
  //   delDoc(id)
  // };

  const convert2base64 = (file) => {
    setDocumentName(file.name);

    const reader = new FileReader();
    reader.onloadend = () => {
      setDocument(reader.result.toString());
      uploadDocument(reader.result.toString(), file.name);
      localstorage.setItem(`${name}name`, file.name);
      localstorage.setItem(`${name}document`, reader.result.toString());
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    setDocumentName(localstorage.getItem(`${name}name`) ? localstorage.getItem(`${name}name`) : userFile_name);
    setDocument(localstorage.getItem(`${name}document`));
  }, []);

  // useEffect(() => {
  //   if (document !== '' && documentName !== '') {
  //     localstorage.setItem(`${name}document`, document);
  //     localstorage.setItem(`${name}name`, documentName);
  //   } else {
  //     localstorage.setItem(`${name}document`, '');
  //     localstorage.setItem(`${name}name`, '');

  //     //   setDocumentName('');
  //     //   setValue(name, '');
  //   }
  // }, [document, documentName]);
  //     //   setDocumentName('');
  //     //   setValue(name, '');
  //   }
  // }, [document, documentName]);

  const handleUpload = (files: File[]) => {
    // console.log(e.target.value);

    if (files && files.length > 0) {
      convert2base64(files[0]);
    }
  };
  const handleSubmit = (e) => {
    const selectedFile = e.target.files[0];
    documentValidate
      .validate({ file: selectedFile })
      .then(() => {
        // File is valid, proceed with upload logic
        handleUpload(e.target.files);
      })
      .catch((error) => {
        const errorMessage = error.errors[0];
        toast.error(errorMessage);
      });
  };

  const ImageDel = (id: number) => {
    // handleShowDelete();
    // handleDeleteDocument(id);
    // if (changed) {
    // }
    if (id) {
      delDoc(id)
        .then((res) => {
          toast.success(`Removed`);
        })
        .catch((err) => toast.error(err.message));
    } else {
      localStorage.removeItem(`${name}document`);
      localStorage.removeItem(`${name}name`);
      toast.success(`Removed`);
    }
  };

  return (
    <div className="form-row" id={style.form_row}>
      <div className="form-group offset-md-2 col-md-4">
        <p className="m-0">{name}</p>
        {/* <p className={`${style.profile_text_color} m-0`}>(Should be in .abc format)</p> */}
      </div>
      <div className="form-group offset-md-2 col-md-4">
        <div className="d-flex justify-content-end ">
          {!documentName || documentName === '' ? (
            <>
              <input type="file" id={name} hidden={true} onChange={handleSubmit} />
              <label
                htmlFor={name}
                style={{
                  background: '#FFFFFF',
                }}
                className={`${style.update_profile_btn_outline} btn`}
              >
                <i
                  className="bi bi-upload"
                  style={{
                    marginRight: '8px',
                  }}
                />
                Upload
              </label>
            </>
          ) : (
            <div className="m-2">
              <span>
                {documentName}
                <button
                  style={{ border: 'none', marginRight: '20px' }}
                  onClick={() => {
                    ImageDel(userFileId);
                    setDocument('');
                    setDocumentName('');
                    // setValue(name, '');
                  }}
                >
                  <i className="bi bi-trash text-danger" />
                </button>
              </span>
            </div>
          )}
        </div>
      </div>
      {/* <DeleteModal
        title="Document"
        showDelete={showDelete}
        handleCloseDelete={handleCloseDelete}
        delId={userFileId}
        changed={changed}
        delName={documentName}
        setChanged={setChanged}
      >
        <p>Are you sure you want to delete {documentName} Document?</p>
      </DeleteModal> */}
    </div>
  );
};

export default UploadDocumentType;
