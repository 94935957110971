#image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 35px;
  width: 21%;
}

@media screen and (min-width: 1024px) and (max-width: 1030px) {
  #image {
    display: block;
    width: 30%;
  }
}

@media screen and (min-width: 540px) and (max-width: 770px) {
  #image {
    width: 40%;
  }
}
@media screen and (min-width: 560px) and (max-width: 670px) {
  #image {
    padding-top: 6px;
    width: 40%;
  }
}

@media screen and (max-width: 420px) {
  #image {
    width: 75%;
  }
}
